import { CommonMargin } from 'components/_layout/CommonMargin';
import SEO from 'components/_layout/Seo';
import React from 'react';
import { RelativeSection } from 'sections/Offer/Offer.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import styled from 'styled-components';
import { BenefitsSection } from 'sections/DeepTech/DataSetsNeuralNetworksTraining/components/BenefitsSection';
import { ImageWithTextSection } from 'sections/DeepTech/DataSetsNeuralNetworksTraining/components/ImageWithTextSection';
import { ChooseCodaheadSection } from 'sections/DeepTech/DataSetsNeuralNetworksTraining/components/ChooseCodaheadSection';
import { DistinctSection } from 'sections/DeepTech/DataSetsNeuralNetworksTraining/components/DistinctSection';
import { OurOfferSection } from 'sections/_universal/OurOfferSection';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';

export default () => {
  const isMd = useBreakpoint('md');

  return (
    <>
      <SEO
        title="Datasets for Neural Network Training Services"
        meta={[
          {
            name: 'keywords',
            content:
              'Datasets For Neural Network Training, datasets for training neural networks, Data sets training, neural network training, Neural Network Architecture',
          },
        ]}
        description={
          "Enhance your AI projects with quality datasets for training neural networks. Discover Codahead's reliable solutions for AI development."
        }
      />

      <CommonMargin>
        <StyledSection
          title="Data sets & Neural networks training"
          variant="secondary"
          id="Data sets & Neural networks training"
          isMobile={isMd}
          withBreadCrumbs
        >
          <HeaderSection>
            From speech recognition to advanced sound analysis, we use AI to create seamless, engaging experiences that
            boost efficiency and open up new possibilities. Whether you're developing voice assistants, audio analytics
            tools, or sound-powered IoT devices, we’re here to bring your ideas to life. Let’s make some noise and
            create something amazing together!
          </HeaderSection>
        </StyledSection>

        <DistinctSection />
        <ImageWithTextSection />
        <ChooseCodaheadSection />
        <BenefitsSection />
        <OurOfferSection />
        <TakeThisStepSection />
      </CommonMargin>
    </>
  );
};

const HeaderSection = styled.div`
  margin-top: 40px;
  max-width: 900px;
  font-size: 20px;
  line-height: 1.65;
  font-weight: 300;
`;

const StyledSection = styled(RelativeSection)`
  margin-top: 24px;
`;
